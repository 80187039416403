import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ESSENTIAL, PREMIUM } from "../Routes/Routesconstants"
import { Helmet } from "react-helmet"
export default function Homepage() {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Sonos Pro - Listen to Sample Music Plans</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Listen to examples of music included in each Sonos Pro plan." />
        <meta name="keywords" content="Sonos Pro" />
        <meta name="author" content="Sonos Pro" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="mt-8 sm:mt-24">
        <h1 className="text-center text-Hding font-medium">Sample Plans</h1>
        <p className="mt-3 text-subheading text-para mx-4 sm:mx-0 text-center">
          Hear some of the music each Sonos Pro plan has to offer before you subscribe.
        </p>

        <div className="flex flex-col sm:flex-row WdthSave mt-4 sm:my-32 my-8 justify-center gap-4 items-center text-white">
          <div
            onClick={() => {
              navigate(ESSENTIAL)
            }}
            className="wdth50Percetge"
          >
            <h1 className="text-4xl rounded-t pt-6 pb-6 px-6 font-medium mb-6 bg-secondary text-accent">Essential</h1>
            <p className="text-lg px-6 text-md font-regular text-darkGrey mb-6">
              Everything you need to set the right tone for your business.
            </p>
            <ul className="list-disc list-none font-regular text-listColor px-4 pl-6 mb-6">
              <li className="mb-2 custom-bullet">Dozens of stations</li>
              <li className="mb-2 custom-bullet">Music by independent artists</li>
              <li className="mb-2 custom-bullet">Content organized by business type and season</li>
              <li className="mb-2 custom-bullet">Filtered for explicit content and themes</li>
              <li className="mb-2 custom-bullet">Commercially licensed</li>
              <li className="custom-bullet">No ads</li>
            </ul>
            <button className=" mt-2 mb-10 border-2 border-accent  text-accent px-6 py-4 ml-5 text-sm font-regular rounded-full">
              Preview content
            </button>
          </div>

          <div
            onClick={() => {
              navigate(PREMIUM)
            }}
            className="wdth50Percetge"
          >
            <h1 className="text-4xl rounded-t pt-6 pb-6 px-6 font-medium mb-6 bg-cardsColor text-white">Premium</h1>
            <p className="text-lg font-regular px-6 text-md text-darkGrey mb-6">
              A greater selection of music to help fine-tune your brand and customer experience.
            </p>
            <ul className="list-disc font-regular list-none text-listColor px-4 pl-6 mb-6">
              <li className="mb-2 star-bullet">Hundreds of stations</li>
              <li className="mb-2 star-bullet">Music by mainstream artists</li>
              <li className="mb-2 star-bullet">Content organized by business type, genre, decades, season, and mood</li>
              <li className="mb-2 custom-bullet">Filtered for explicit content and themes</li>
              <li className="mb-2 custom-bullet">Commercially licensed</li>
              <li className="custom-bullet">No ads</li>
            </ul>
            <button className=" mt-2 mb-10 border-2 border-accent  text-accent px-6 py-4 ml-5 text-sm font-regular rounded-full">
              Preview content
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
