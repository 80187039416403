import getBlobDuration from "get-blob-duration"

/**
 * Fetches the genre from the specified URL.
 *
 * @param {string} url - The URL to fetch the genre from.
 * @return {Promise} A Promise that resolves to the response JSON.
 */
export const fetchGenre = async (url) => {
  try {
    const paths = [process.env.REACT_APP_PREVIEW_PATH, url]
    // URL doesn't take multiple paths parameters, and the url may not proper path format.
    const separator = url.startsWith("/") ? "" : "/"
    const urlPath = paths.join(separator)
    const genreUrl = new URL(urlPath, process.env.REACT_APP_MOOD_HOST)
    console.info(`Genre URL is ${genreUrl}`)
    const response = await fetch(genreUrl)
    return response?.json()
  } catch (error) {
    console.log(error)
  }
}

/**
 * Filters the given data array based on the specified type.
 *
 * @param {Array} data - The data array to filter.
 * @param {type} type - The type to filter by.
 * @return {Array} - The filtered data array.
 */
export const listSongs = (data, type) => {
  return data.filter((item) => item.id_category === type)
}

/**
 * Extracts data from the given object and returns an array of genre objects.
 *
 * @param {Object} data - The object containing the data to be extracted.
 * @return {Array} An array of genre objects containing genre names, genre ids, and images.
 */
export const extractData = (data) => {
  let result = []

  /**
   * Traverses the given object to extract category information and pushes it to the result array.
   *
   * @param {object} obj - The object to be traversed.
   * @return {undefined} This function does not have a return value.
   */
  function traverseCategories(obj) {
    // Pushing current object category_name and id_category to the result array
    if (obj.category_name !== "Pro" && obj.category_name !== "Essentials" && obj.category_name !== "Genre") {
      result.push({
        genreName: obj.category_name,
        genreId: obj.id_category,
        image: obj.category_art,
      })
    }

    // If the object has nested categories, traverse them as well
    if (obj.categories && obj.categories.length > 0) {
      obj.categories.forEach((childObj) => traverseCategories(childObj))
    }
  }

  // Initiate the traversal with the root object
  traverseCategories(data)

  return result
}

export const extractGenre = (data) => {
  let result = []
  const indexOfGenre = data?.categories?.findIndex((item) => {
    return item?.category_name === "Genre"
  })

  console.log(data?.categories?.[indexOfGenre].categories)
  if (indexOfGenre === -1) return result

  data?.categories?.[indexOfGenre].categories.forEach((item) => {
    result.push({
      genreName: item.category_name,
      genreId: item.id_category,
      image: item.category_art,
    })
  })
  return result
}

/**
 * Generates a modified URL by changing the resolution of an image.
 *
 * @param {string} imageUrl - The URL of the image.
 * @param {string} extension - The desired resolution to append to the base URL.
 * @return {string} The modified URL with the desired resolution.
 */
export const changeResolutionOfImage = (imageUrl, extension) => {
  if (imageUrl === "") return
  const baseUrl = imageUrl?.substring(0, imageUrl.lastIndexOf("."))
  const fileExtension = imageUrl?.substring(imageUrl.lastIndexOf("."))

  // Append the desired suffix to the base URL
  const modifiedUrl = `${baseUrl}_${extension}${fileExtension}`
  return modifiedUrl
}

/**
 *
 * @param {*} seconds
 * converts seconds into minutes, a standard method of display songs duration
 * @returns {minutes:seconds}
 */
export const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`
}

/*
Returns the duration of the audio file from the link
*/
export const getMusicDuration = async (musicLink) => {
  try {
    const duration = await getBlobDuration(musicLink)
    return duration
  } catch (error) {
    console.error("Unable to get music duration", error)
    return 0
  }
}
