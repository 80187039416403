import React, { useEffect, useRef, useState } from "react"
import { fetchGenre, extractGenre, getMusicDuration, formatDuration } from "../Assets/Components/Genre"
import PlayButton from "../Assets/Images/Button2.svg"
import PauseButton from "../Assets/Images/Button.svg"
import ReactPlayer from "react-player"
import menuSymbol from "../Assets/Images/menu.svg"
import xSymbol from "../Assets/Images/x.svg"
import { toast } from "react-toastify"
import LazyImage from "../Assets/Components/LazyImage"
import Skeleton from "react-loading-skeleton"
import unmuteimg from "../Assets/Images/sound.png"
import { Helmet } from "react-helmet"
export default function Premium() {
  const [genreList, setGenreList] = useState([])
  const [channelList, setChannelList] = useState({})
  const [typeSelected, setTypeSelected] = useState("")
  const [playValue, setPlayValue] = useState({
    totalDuration: 0,
    currentTime: 0,
    percentage: 0,
  })
  const [percentage, setPercentage] = useState(0)
  const [musicLink, setMusicLink] = useState("")
  const [albumData, setAlbumData] = useState({})
  const [play, setPlay] = useState(false)
  const [playList, setPlayList] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const playerRef = useRef(null)

  const [volume, setVolume] = useState(0.1)
  const [isMuted, setIsMuted] = useState(false)

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value)
    setVolume(newVolume)
    if (playerRef.current) {
      setIsMuted(newVolume === 0)
    }
  }

  const handleMuteToggle = () => {
    const newMuteState = !isMuted
    setIsMuted(newMuteState)
    const newVolume = newMuteState ? 0 : 0.5 // You can adjust the default volume when unmuting
    setVolume(newVolume)
  }

  const handleCategoryClick = (genreId) => {
    setTypeSelected(genreId)
    setPlayValue({
      totalDuration: 0,
      currentTime: 0,
    })
    setPercentage(0)
    scrollToTop()
  }

  useEffect(() => {
    fetchGenre("pro")
      .then((res) => {
        setGenreList(extractGenre(res))
        setIsLoading(false)
        setTypeSelected(extractGenre(res)[0]?.genreId)
      })
      .catch((err) => toast.error(err?.response?.message))
  }, [])

  useEffect(() => {
    if (!typeSelected) {
      return
    }

    fetchGenre(typeSelected)
      .then((res) => {
        setIsLoading(false)
        setPercentage(0)
        let result = []
        if (res.hasOwnProperty("categories") && Array.isArray(res["categories"]) && res["categories"].length > 0) {
          let dataArray = []
          result = res.categories
          result.forEach((item) => {
            item?.programs?.forEach((program) => {
              dataArray.push(program)
            })
          })
          setMusicLink(dataArray[0]?.program_sample)
          setActiveItem(dataArray[0]?.program_sample)
          setChannelList(dataArray)
          setPercentage(0)
        } else {
          result = res.programs
          setMusicLink(result[0]?.program_sample)
          setActiveItem(result[0]?.program_sample)
          setChannelList(result)
          setPercentage(0)
        }
      })
      .catch((err) => toast.error(err?.response?.message))
  }, [typeSelected])

  useEffect(() => {
    if (Array.isArray(channelList) && channelList.length > 0) {
      const filteredData = channelList.filter((item) => {
        return item.program_sample === musicLink
      })

      setAlbumData(filteredData[0])

      const index = channelList.findIndex((item) => item.program_sample === musicLink)

      const cloneArray = [...channelList]

      const filterArray = index !== -1 ? cloneArray.slice(index) : []

      if (filterArray.length === 0) return

      let newPlayList = []

      filterArray.map((item) => {
        return newPlayList.push(item.program_sample)
      })

      getMusicDuration(musicLink).then((duration) => {
        setPlayValue((prev) => ({
          ...prev,
          totalDuration: duration,
        }))
      })

      setPlayList(newPlayList)
    }
  }, [musicLink, channelList])

  /**
   * Scrolls the window to the top of the page with a smooth behavior.
   *
   * @param {none} none - This function does not accept any parameters.
   * @return {none} This function does not return any value.
   */
  const scrollToTop = () => {
    setPercentage(0)
    window.scrollTo({
      top: "0",
      left: "0",
      behavior: "smooth",
    })
  }

  /**
   * Changes the current song to the next song in the playlist.
   *
   * @return {undefined} There is no return value.
   */
  const changeSong = () => {
    const index = playList.findIndex((item) => item === musicLink)
    const playListLength = playList.length
    if (index === playListLength - 1) {
      setMusicLink(playList[0])
      return
    }
    setMusicLink(playList[index + 1])
  }

  /**
   * Toggles the menu open or closed.
   *
   * @return {undefined} No return value.
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  /**
   *
   * @param {progress} progress
   * @return {seconds} of music played.
   */

  const handleProgress = (progress) => {
    const currentTime = progress.playedSeconds
    setPlayValue((prev) => ({
      ...prev,
      currentTime: currentTime,
    }))
    if (playValue.currentTime !== 0 && playValue.totalDuration !== 0) {
      const percentage = (playValue.currentTime / playValue.totalDuration) * 100
      setPercentage(percentage)
    } else {
      setPercentage(0)
    }
  }

  /**
   *
   * Use for active class or deactive
   *
   */

  const [activeItem, setActiveItem] = useState(null)
  const setMusicActive = (itemId) => {
    setActiveItem(itemId)
    setMusicLink(itemId)
    setPlay(true)
    setPlayValue({
      totalDuration: 0,
      currentTime: 0,
    })
    setPercentage(0)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const calculateGradient = (value, max) => {
    const percentage = (value / max) * 100
    return `linear-gradient(to right, White ${percentage}%, gray ${percentage}%)`
  }

  const handleSeek = (event) => {
    const newTime = parseFloat(event.target.value)
    setPlayValue((prev) => ({
      ...prev,
      currentTime: newTime,
    }))
    playerRef.current.seekTo(newTime, "seconds")
  }

  return (
    <>
      <Helmet>
        <title>Sonos Pro - Listen to a Premium Plan Music Sample</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Listen to examples of music included in the Sonos Pro Premium plan." />
        <meta name="keywords" content="Sonos Pro" />
        <meta name="author" content="Sonos Pro" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="flex flex-col lg:flex-row">
        <div className="hidden flex flex-col text-medium bg-primary lg:inset-y-0 lg:flex lg:w-80 border-gray h-[calc(100vh-64px)] scrollRight overflow-y-auto">
          {genreList?.length === 0 ? (
            <div className="p-5">
              {" "}
              <Skeleton duration={1} count={20} height={15} />{" "}
            </div>
          ) : (
            genreList?.map((item, index) => (
              <div
                onClick={() => handleCategoryClick(item.genreId)}
                className={`${
                  typeSelected === item.genreId ? "border-l-4 border-accent" : ""
                } flex p-4 items-center cursor-pointer`}
                key={index}
              >
                <LazyImage src={item.image} className="h-10 w-10" alt={item.genreName} />
                <h4 className=" HdingtextLeft text-sm whitespace-nowrap text-essence">{item.genreName}</h4>
              </div>
            ))
          )}
        </div>

        <div className="bg-white text-center my-3 block lg:hidden">
          <h1 className="text-2xl font-medium">
            {genreList?.filter((item) => item?.genreId === typeSelected).map((item) => item?.genreName)}
          </h1>
          <button onClick={toggleMenu} className="inline-flex text-xl font-regular gap-2 items-center mrginBtn">
            Browse genres
            <LazyImage src={isMenuOpen ? xSymbol : menuSymbol} alt="menu" />
          </button>

          <div className={`${isMenuOpen ? "ShowClass" : "hidden"}`}>
            {genreList.map((item, index) => (
              <div
                onClick={() => {
                  setTypeSelected(item?.genreId)
                  toggleMenu()
                  scrollToTop()
                }}
                className={`flex p-8 border-b bg-secondary items-center cursor-pointer`}
                key={index}
              >
                <LazyImage src={item?.image} className="h-10 w-10" alt={item?.genreName} />
                <h4 className="font-medium text-sm whitespace-nowrap text-essence">{item?.genreName}</h4>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:ml-42 w-full h-[calc(100vh-64px)] overflow-y-auto p-8 scrollRight bg-pageColor">
          <div className="flex flex-col sm:flex-row  gap-8 flextData">
            <div className="HgtFxd">
              {isLoading ? (
                <Skeleton duration={1} count={1} height={250} width={250} />
              ) : (
                <img className="w-80" src={albumData?.program_art} alt="" />
              )}
            </div>

            <div className="text-white text-center sm:text-left font-medium FlexWdthMn">
              <h1 className="text-xl sm:text-3xl">
                {isLoading ? (
                  <Skeleton duration={2} count={1} height={40} width={500} />
                ) : (
                  <> {albumData?.program_name === "" ? "DDDD" : albumData?.program_name}</>
                )}
              </h1>
              <h3 className="text-lg sm:text-lg font-regular">
                {isLoading ? (
                  <Skeleton duration={2} count={1} height={20} width={800} />
                ) : (
                  <> {albumData?.program_environment} </>
                )}
              </h3>
              <p className="text-descriptionColor sm:text-sm text-md">
                {isLoading ? (
                  <Skeleton duration={2} count={2} height={10} width={700} />
                ) : (
                  <> Sample Artists: {albumData?.program_artists} </>
                )}
              </p>

              <div className="flex relative justify-start align-middle MrginBtn">
                <button className="shadow-md btnPlay" onClick={() => setPlay(!play)}>
                  {isLoading ? (
                    <Skeleton duration={1} count={1} height={70} borderRadius="50%" width={70} />
                  ) : (
                    <img className="w-16 rounded-full h-16" src={play ? PauseButton : PlayButton} alt="playbutton" />
                  )}
                </button>
                <div className="flex justify-left items-center  unMuteIconDta">
                  <button onClick={handleMuteToggle} className="cursor-pointer SizeChne">
                    {isMuted ? (
                      <>
                        <div className="LineTxt"></div>
                        <img src={unmuteimg} alt="Mute" />
                      </>
                    ) : (
                      <img src={unmuteimg} alt="Unmute" />
                    )}
                  </button>
                  <input
                    type="range"
                    min={0}
                    max={1}
                    step={0.01}
                    value={volume}
                    style={{
                      background: calculateGradient(volume, 1),
                    }}
                    onChange={handleVolumeChange}
                    className="w-[100%] h-0.5 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    id="volumeSlider"
                  />
                </div>
              </div>

              <div className="bg-accent  w-100">
                <ReactPlayer
                  ref={playerRef}
                  width={"80%"}
                  duration={120}
                  height={"0px"}
                  onEnded={changeSong}
                  url={playList}
                  volume={volume}
                  playing={play}
                  style={{ color: "white" }}
                  controls={false}
                  playIcon={false}
                  onProgress={handleProgress}
                />
              </div>

              <div className="flex mt-4 justify-center sm:justify-normal items-center gap-4">
                {formatDuration(playValue.currentTime)}
                <input
                  type="range"
                  min={0}
                  max={playValue.totalDuration}
                  step={0.01}
                  value={playValue.currentTime}
                  onChange={handleSeek}
                  style={{
                    background: `linear-gradient(to right, white ${percentage}%, gray ${percentage}%)`,
                  }}
                  className="w-[60%] h-0.5 bg-gray-200 rounded-lg appearance-none "
                  id="myRangeInput"
                />
                {formatDuration(playValue?.totalDuration)}
              </div>
            </div>
          </div>

          {channelList?.length > 0 && (
            <div className="gap-6 textMarginBtm w-full  pt-8 text-sm text-descriptionColor items-center justify-between">
              <h3 className="w-[20%]">Title</h3>
              <h3 className="w-[40%]">Description</h3>
              <h3 className="w-[40%]">Sample Artists</h3>
            </div>
          )}

          {channelList?.length > 0 &&
            channelList?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    onClick={() => setMusicActive(item.program_sample)}
                    className={`flex cursor-pointer lg:hidden flex-row items-center p-3 rounded-lg MobileViewPding ${
                      activeItem === item.program_sample ? "activeMusic" : ""
                    }`}
                  >
                    <div className="w-32 h-32 mb-4 mb-0 mr-4 bg-gray-300 rounded-lg flex-shrink-0">
                      <LazyImage src={item?.program_art} alt="" />
                    </div>

                    <div className="WdthPsrData">
                      <div className="text-xl font-semibold text-primary mb-2">{item?.program_name}</div>

                      <div className="text-gray-500 mb-2">{item?.program_environment}</div>

                      <div className="text-sm text-gray-400 block">{item?.program_artists}</div>
                    </div>

                    <div className="mt-0 p-2">
                      <LazyImage src={PlayButton} className="rounded-full w-12 h-12" alt="playbutton" />
                    </div>
                  </div>

                  <div
                    className={`hidden w-full  cursor-pointer PriumNberText py-4 gap-6 text-sm text-primary items-center justify-between ${
                      activeItem === item.program_sample ? "activeMusic" : ""
                    }`}
                    id={item.program_sample}
                    onClick={() => setMusicActive(item.program_sample)}
                  >
                    <div className="flex items-center gap-2 w-[20%]">
                      <LazyImage className="w-12 h-12" src={item.program_art} alt="" />
                      <h4>{item.program_name}</h4>
                    </div>
                    <h4 className="TextCOlor w-[40%]">{item.program_environment}</h4>
                    <h4 className="TextCOlor w-[40%]">{item.program_artists}</h4>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}
