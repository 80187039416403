import React from "react"
import HeaderImage from "../Images/image 116.svg"
import { Link, useLocation } from "react-router-dom"
import { ESSENTIAL, PREMIUM } from "../../Routes/Routesconstants"

export default function Header() {
  const location = useLocation()

  const checkPages = (type) => {
    let className
    if (type === "essential") {
      if (location.pathname === ESSENTIAL) {
        className = "text-accent underline"
      } else {
        className = "text-lightColor"
      }
    }
    if (type === "premium") {
      if (location.pathname === PREMIUM) {
        className = "text-accent underline"
      } else {
        className = "text-lightColor"
      }
    }
    return className
  }
  const isPlayboxPage = location.pathname === PREMIUM || location.pathname === ESSENTIAL

  return (
    <div className="header">
      <div className="w-full flex flex-col sm:items-center sm:flex-row sm:justify-between py-5">
        <a href="https://www.sonos.com/en-us/sonos-pro/pricing" rel="noreferrer" target="_blank">
          <img
            className="w-auto sm:w-[130px] sm:ml-[3.7037037037037037vw] ml-[7.407407407407407vw]"
            src={HeaderImage}
            alt="Header"
          />
        </a>
        <div className="mt-4 sm:mt-0 items-center gap-4 flex text-sm fontUpdate mx-8">
          {isPlayboxPage && (
            <div className="font-regular flex  gap-4">
              <Link to={ESSENTIAL} className={`${checkPages("essential")}`}>
                Essential
              </Link>
              <Link to={PREMIUM} className={checkPages("premium")}>
                Premium
              </Link>
            </div>
          )}
          <a
            href="https://www.sonos.com/en-us/sonos-pro/pricing"
            rel="noreferrer"
            target="_blank"
            className="bg-accent w-auto text-lg sm:text-base font-regular text-white px-6 sm:px-4 py-2 rounded-full mr-[3.7037037037037037vw]"
          >
            Get started
          </a>
        </div>
      </div>
    </div>
  )
}
