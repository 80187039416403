import React, { useEffect, useRef } from "react"

const LazyImage = ({ src, alt = "", className = "" }) => {
  const imgRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target
          img.src = img.dataset.src
          observer.unobserve(img)
        }
      })
    })

    if (imgRef.current) {
      observer.observe(imgRef.current)
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current)
      }
    }
  }, [src])

  return <img className={className} ref={imgRef} data-src={src} alt={alt} />
}

export default LazyImage
