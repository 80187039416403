import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ESSENTIAL, PREMIUM } from "./Routesconstants"
import Header from "../Assets/Components/Header"
import Footer from "../Assets/Components/Footer"
import Homepage from "../Pages/Homepage"
import Essential from "../Pages/Essential"
import Premium from "../Pages/Premium"

export const RouteComponent = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path={PREMIUM} element={<Premium />} />
        <Route path={ESSENTIAL} element={<Essential />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}
