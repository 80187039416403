import React from "react"
import footerImage from "../Images/3x4-Portrait.jpg"
import ArrowImage from "../Images/Rectangle 8.svg"
import { useLocation } from "react-router-dom"
import { ESSENTIAL, PREMIUM } from "../../Routes/Routesconstants"

export default function Footer() {
  const location = useLocation()
  const changeColor = location.pathname === PREMIUM || location.pathname === ESSENTIAL
  return (
    <div className="relative MarginTp">
      <div
        className={`flex flex-col sm:flex-row gap-6 ${
          !changeColor ? "bg-accent" : "bg-cardsColor"
        } justify-between items-left sm:items-center text-white p-12 sm:p-20`}
      >
        <div className="order-2 sm:order-1">
          <h1 className="font-medium">
            <span className="sm:block hidden text-5xl">
              Let's talk <br />
              business - <br />
              yours <br />
              specifically
            </span>
            <span className="sm:hidden text-[21px]">Let's talk business — yours specifically</span>
          </h1>
          <p className="mt-4 text-lg ">
            We're standing by to answer questions,
            <br /> help you select products, connect you <br /> to a local installer, and maximize your
            <br /> Sonos experience.
          </p>
          <a
            href="https://www.sonos.com/en-us/sonos-pro/pricing"
            rel="noreferrer"
            target="_blank"
            className={`${
              !changeColor ? "bg-accent" : "bg-cardsColor"
            }  inline-flex border-primary mt-6 items-center gap-2 border-2 border-accent text-white rounded-full py-3 px-5`}
          >
            <span className="text-md font-medium">Let's connect</span>
            <img className="h-3" src={ArrowImage} alt="arrow" />
          </a>
        </div>
        <img className="w-full sm:w-3/5 order-1 sm:order-2" src={footerImage} alt="talking-buddies" />
      </div>
    </div>
  )
}
