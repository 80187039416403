import { RouteComponent } from "./Routes/Routes"
import { ToastContainer } from "react-toastify"
import "react-loading-skeleton/dist/skeleton.css"
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <RouteComponent />
    </div>
  )
}

export default App
